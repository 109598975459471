<template>
  <div>
    <link
      rel="stylesheet"
      href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
      integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
      crossorigin="anonymous"
    />

    <Header
      :title="headerTitle"
      :buttons="headerButtons"
      @click:menu="onHeaderButtonClick"
      class="shadow"
    />
    <small
      v-if="episode && episode.release && episode.release.state==='PENDING'"
    >Release scheduled for {{ asCalendar(episode.release.releaseAt) }}</small>
    <!-- container start -->
    <div class="container py-5">
      <!-- meta -->
      <div class="row pt-3 pb-5">
        <div class="col">
          <div v-cloak v-if="formData" class="row d-flex flex-row-reverse text-left">
            <div v-cloak class="col-md-12 col-lg-8">
              <form class="form col-12 text-left" @submit.prevent="() => false">
                <div class="form-group">
                  <label>Episode Title</label>
                  <input type="text" v-model="formData.title" class="form-control" @click="onInputClick('episodeTitle')" />
                </div>

                <div class="form-group">
                  <label>Episode Title (English)</label>
                  <input type="text" v-model="formData.titleEn" class="form-control" @click="onInputClick('episodeTitleEn')" />
                </div>

                <div class="form-group">
                  <label>Episode Summary</label>
                  <textarea v-model="formData.summary" class="form-control" rows="3" @click="onInputClick('episodeSummary')"></textarea>
                </div>
              </form>
            </div>

            <!-- cover-image -->
            <div v-cloak class="col-md-12 col-lg-4">
              <div class="col">
                <img
                  :src="episode.coverImageUrl"
                  class="rounded cover-image"
                  height="300px"
                  width="300px"
                />
                <input
                  type="file"
                  hidden="hidden"
                  id="cover-image-upload"
                  ref="elCoverImageUpload"
                  @click="onInputClick('coverImage')"
                  v-on:change="
                                        episodeActionUploadCoverImage($event)
                                    "
                />
                <button class="update-img" @click="$refs.elCoverImageUpload.click()">
                  <i class="pratilipi-icons">&#xe81f;</i>
                </button>
              </div>
              <small>*Image should have max size of 1.5MB and max resolution of 500px(W)*500px(H) </small>
            </div>
          </div>
        </div>
      </div>
      <!-- status message -->
      <div v-if="status">{{ status }}</div>
      <!-- image count / bulk upload if empty -->
      <div v-if="chapter && chapter.imageCount > 0">
        Showing {{ chapter.imageCount }} images
        <button
          @click="contentActionRemoveAll"
          class="btn btn-danger btn-sm"
        >Remove All Images</button>
      </div>
      <div v-else>
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="bulk-add-images"
            multiple="true"
            v-on:change="contentActionUploadBatch($event)"
          />
          <label class="custom-file-label" for="bulk-add-images">Add first batch images</label>
        </div>
        <small>*Image should have max size of 1.5MB and max resolution of 1024px(W)*800px(H) </small>
      </div>
      <!-- content images -->
      <div v-if="pagelets" class="row pt-3 pb-2">
        <div v-for="(pagelet, i) in pagelets" :key="pagelet.data.name">
          <div class="col mb-1" v-if="!true">
            <img :src="getImageUrl(pagelet.data)" height="200px" width="200px" />
          </div>
          <div class="card border-0" v-else>
            <div class="row no-gutters">
              <div class="col-md-4" style="width:300px">
                <img :src="getImageUrl(pagelet.data)" class="card-img-top" />
              </div>
              <div class="col-md-8 border">
                <div class="card-body">
                  <div class="custom-file align-self-start">
                    <input
                      type="file"
                      class="custom-file-input"
                      multiple="multiple"
                      @click="onInputClick('content_images')"
                      :id="pagelet.data.name + '-before'"
                      v-on:change="
                                                contentActionUploadBefore(
                                                    i,
                                                    $event
                                                )
                                            "
                    />
                    <label
                      class="custom-file-label"
                      :for="pagelet.data.name + '-before'"
                    >Add image before this image</label>
                  </div>
                  <div
                    class="btn-group my-2 align-self-center"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button class="btn btn-danger" @click="contentActionRemove(i)">Remove This Image</button>
                  </div>
                  <div class="custom-file align-self-end">
                    <input
                      type="file"
                      class="custom-file-input"
                      multiple="multiple"
                      :id="pagelet.data.name + '-after'"
                      v-on:change="
                                                contentActionUploadAfter(
                                                    i,
                                                    $event
                                                )
                                            "
                    />
                    <label
                      class="custom-file-label"
                      :for="pagelet.data.name + '-after'"
                    >Add image after this image</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- checkpoints -->
      <div class="row pt-3 pb-2">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <strong>Checkpoints</strong>
            </div>

            <table class="table table-striped">
              <tbody>
                <tr v-for="(checkpoint, i) in checkpoints" :key="i">
                  <th scope="row">{{ checkpoints.length - i }}</th>
                  <td>
                    {{ timeAgo(checkpoint.timestamp) }} with
                    {{ checkpoint.images.length }} images
                  </td>
                  <td :title="checkpoint.timestamp"></td>
                  <td>
                    <button
                      class="btn btn-sm btn-outline-dark"
                      @click="
                                                contentActionRestore(checkpoint)
                                            "
                    >Restore</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="card-footer">
              A checkpoint is saved every time content is updated
              (upto 10 for an episode).
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- container end -->
  </div>
</template>
<script>
import * as _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import Header from "../components/Header";
import mixins from '@/mixins';

import DataAccessor, {
  apiSeriesV2,
  apiComic,
  apiContentV1,
  apiPratilipiV2,
  apiPratilipiV1
} from "../http/DataAccessor";

export default {
  components: {
    Header
  },

  created() {
    if (!this.getUserDetails.authorId) {
      this.$router.replace({ name: "LoginPage" });
      return;
    }

    this.user = this.getUserDetails;
    this.init();
  },

  mixins: [mixins],
  watch: {
    "getUserDetails.isGuest"(isGuest) {
      if (isGuest) {
        this.$router.replace({ name: "Login_Page" });
      } else {
        this.init();
      }
    }
  },

  data() {
    return {
      comic: null,
      episode: null,
      chapter: null,
      formData: null,
      errors: {},
      status: "",
      showImageModal: false,
      // for tui-image-editor
      showImageEditor: false,
      useDefaultUI: true,
      options: {
        cssMaxWidth: 700,
        cssMaxHeight: 1000
      }
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    partNumber() {
      return 0;
    },
    checkpoints() {
      if (!this.episode) {
        return [];
      }

      const key = `content_checkpoints_${this.episode.pratilipiId}`;
      const content = this.content;
      const current = localStorage.getItem(key);
      return JSON.parse(current) || [];
    },
    content() {
      const images = _.map(this.pagelets, "data.name");
      const pratilipiId = this.episode.pratilipiId;

      return images
        .map(name => {
          return `<img src="api/pratilipi/content/comic?pratilipiId=${pratilipiId}&name=${name}"/>`;
        })
        .join("");
    },
    pagelets() {
      return _.get(this.chapter, ["pages", 0, "pagelets"], null);
    },
    headerTitle() {
      let title = "Loading Episode...";

      if (this.comic && this.episode) {
        title = `${this.comic.displayTitle} / ${this.episode.displayTitle}`;
      }

      return title;
    },
    headerButtons() {
      let buttons = [
        {
          id: "GOTO-SERIES",
          text: "Back to Comic",
          class: "d-none d-md-inline-block"
        },
        {
          id: "SAVE-META",
          text: "Save",
          class: "d-none d-md-inline-block"
        },
        // {
        //   id: "PUBLISH",
        //   text: "Publish",
        //   class: "d-none d-md-inline-block btn-danger",
        //   show: _.get(this.episode, "state") === "DRAFTED"
        // },
        {
          id: "UNPUBLISH",
          text: "Unpublish",
          class: "d-none d-md-inline-block btn-dark",
          show: _.get(this.episode, "state") === "PUBLISHED"
        },
        // {
        //   id: "DELETE-EPISODE",
        //   text: "delete episode",
        //   class: "d-none d-md-inline-block btn-danger"
        // }
      ].filter(b => ("show" in b ? b.show : true));

      return buttons;
    }
  },
  methods: {
    async init() {
      const { comicId, comic, episode } = this.$router.currentRoute.params;

      if (!comic || !episode || this.user.authorId != comic.author.authorId) {
        this.goBackToComic();
        return;
      }

      this.onEpisodeLoad(comic, episode);
    },

    async onEpisodeLoad(comic, episode) {
      // if (!comic.auth.isAuthorized) {
      //   return this.$router.replace({ name: "ComicsDashboard" });
      // }

      this.comic = comic;
      this.episode = episode;
      this.formData = _.pick(this.episode, ["title", "titleEn", "summary"]);

      const pratilipiId = this.episode.pratilipiId;

      await this.fetchContent(pratilipiId).catch(e => {
        if (e.response.status != 404) {
          throw e;
        }

        return apiContentV1
          .addChapter(pratilipiId)
          .then(() => this.fetchContent(pratilipiId));
      });
    },

    goBackToComic() {
      const { comicId, comic, episode } = this.$router.currentRoute.params;

      this.triggerAnanlyticsEvent("CLICK_BACK_TO_COMIC", "", {
          SERIES_ID: this.comic.seriesId,
          SERIES_NAME: this.comic.titleEn,
          PRATILIPI_ID: this.episode.pratilipiId
        });
      this.$router.replace({
        name: "ComicsDetail",
        params: { comicId }
      });
    },

    async fetchContent(pratilipiId) {
      const { data } = await apiContentV1.getContent(pratilipiId);
      this.chapter = data.content.chapters[0];
    },

    async saveMeta(changes = null) {
      if (changes === null) {
        changes = this.formData;
        this.$confirm("Are you sure you want to make these changes?", "Confirm", "warning").then(() => {
          this.updateEpisode(changes)
          this.triggerAnanlyticsEvent("CLICK_SAVE_CHAPTER", "", {
            SERIES_ID: this.comic.seriesId,
            SERIES_NAME: this.comic.titleEn,
            PRATILIPI_ID: this.episode.pratilipiId
          })
        });
      }

      if(changes.state === "DRAFTED") {
        this.$confirm("Are you sure you want to unpublish this episode?", "Confirm", "warning").then(() => {
          this.updateEpisode(changes)
          this.triggerAnanlyticsEvent("CLICK_UNPUBLISH_CHAPTER", "", {
            SERIES_ID: this.comic.seriesId,
            SERIES_NAME: this.comic.titleEn,
            PRATILIPI_ID: this.episode.pratilipiId
          })
        });
      }

      if(changes.state === "PUBLISHED") {
          if(this.chapter.imageCount === 0) {
              this.$alert("No images have been added!", "Can't publish", "error");
              this.triggerAnanlyticsEvent("SEEN_ALERT_CANT_PUBLISH_EP", "", {
                SERIES_ID: this.comic.seriesId,
                SERIES_NAME: this.comic.titleEn,
                PRATILIPI_ID: this.episode.pratilipiId
              })
              return
          }

        this.$confirm("Are you sure you want to publish this episode?", "Confirm", "warning").then(() => {
          this.updateEpisode(changes)
          this.triggerAnanlyticsEvent("CLICK_PUBLISH_CHAPTER", "", {
            SERIES_ID: this.comic.seriesId,
            SERIES_NAME: this.comic.titleEn,
            PRATILIPI_ID: this.episode.pratilipiId
          })
        });
      }
    },

    async updateEpisode(changes) {
      const data = await apiPratilipiV2.save(this.episode.pratilipiId, changes);
      this.onEpisodeLoad(this.comic, data);
    },

    timeAgo(ms) {
      return moment(ms).fromNow(); //eg. 1 day ago, 2 hours ago etc
    },

    showSeriesPart(part) {
      const { attributes: pratilipi } = part;
      this.$router.push({
        name: "EpisodeDetail",
        params: {
          comicId: pratilipi.seriesId,
          episodeId: pratilipi.pratilipiId
        }
      });
    },

    async deleteEpisode() {
      this.$confirm("Are you sure you want to delete this episode?", "Confirm", "warning").then(async () => {
        const { data } = await apiPratilipiV2.destroy(this.episode.pratilipiId);
        this.triggerAnanlyticsEvent("CLICK_DELETE_CHAPTER", "", {
            SERIES_ID: this.comic.seriesId,
            SERIES_NAME: this.comic.titleEn,
            PRATILIPI_ID: this.episode.pratilipiId
          })
        this.goBackToComic()
      });
    },

    getImageUrl(data) {
      const cdn = "https://static-assets.pratilipi.com/pratilipi/content/image";
      return `${cdn}?pratilipiId=${this.episode.pratilipiId}&name=${
        data.name
      }&width=${Math.min(data.width, 600)}`;
    },

    asCalendar(ms) {
      return moment(ms).calendar();
    },

    async contentActionRestore(checkpoint) {
      const images = checkpoint.images;
      await this.saveContentAndRefresh(images);
    },

    async contentActionRemove(i) {
      const existing = _.map(this.pagelets, "data.name");
      existing.splice(i, 1);

      this.triggerAnanlyticsEvent("CLICK_REMOVE_IMAGE", "", {
        SERIES_ID: this.comic.seriesId,
        SERIES_NAME: this.comic.titleEn,
        PRATILIPI_ID: this.episode.pratilipiId,
        UI_POSITION: i
      });

      await this.saveContentAndRefresh(existing);
    },

    async contentActionRemoveAll() {
      await this.saveContentAndRefresh([]);
    },

    async contentActionUploadBefore(i, ev) {

      await this.handleImageAddition(ev.target.files, createdImageNames => {
        const existing = _.map(this.pagelets, "data.name");
        existing.splice(i, 0, ...createdImageNames);
        return existing;
      })

      this.triggerAnanlyticsEvent("CLICK_ADD_IMAGE", "", {
        SERIES_ID: this.comic.seriesId,
        SERIES_NAME: this.comic.titleEn,
        PRATILIPI_ID: this.episode.pratilipiId,
        UI_POSITION: i
      });
    },

    async contentActionUploadAfter(i, ev) {
      await this.handleImageAddition(ev.target.files, createdImageNames => {
        const existing = _.map(this.pagelets, "data.name");
        existing.splice(i + 1, 0, ...createdImageNames);
        return existing;
      })
    },

    async contentActionUploadBatch(ev) {
      this.triggerAnanlyticsEvent("CLICK_ADD_IMAGES_BULK", "", {
        SERIES_ID: this.comic.seriesId,
        SERIES_NAME: this.comic.titleEn,
        PRATILIPI_ID: this.episode.pratilipiId,
      });
      await this.handleImageAddition(ev.target.files, createdImageNames => {
        return createdImageNames;
      });
    },

    async validateFile(file) {
      return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = window.URL.createObjectURL(file)
          img.onload = () => {
              if (img.width <= 800 && img.height <= 1024  && file.size <= 1572864) {
                  resolve(file);
              } else {
                  reject(new Error("details here"));
              }
          };
        });
    },

    async handleImageAddition(files, manipulator) {
      const pratilipiId = this.episode.pratilipiId;

      let count = files.length;
      this.status = `Uploading. Remaning ${count} of ${files.length} files`;
      let validatedFiles=[];

      validatedFiles = await Promise.allSettled([...files].map(this.validateFile))
          .then(results => results.filter(({status}) =>{ console.log("status")
          return(status === "fulfilled")}))
          .then(validatedFiles => {
            return validatedFiles
          });

      if(count != validatedFiles.length){
      this.$alert("1 or more image size has exceeded 1.5MB or resolution has exceeded 800 pixels(W) X 1024 pixels(H)", "Upload Error!", "error");
      this.triggerAnanlyticsEvent("SEEN_ALERT_UPLOAD_ERROR", "", {
        EVENT_DESCRIPTION: "1 or more image size has exceeded 1.5MB or resolution has exceeded 800 pixels(W) X 1024 pixels(H)"
      })
      }


      const uploads = [...validatedFiles].map((file, i) =>
        apiContentV1.uploadImage(pratilipiId, file.value).then(({ data }) => {
          this.status = `Uploading. Remaning ${--count} of ${
            files.length
          } files`;
          return data.name;
        })
      );

      const results = await Promise.all(uploads);

      const manipulatedResults = _.isFunction(manipulator)
        ? manipulator(results)
        : results;

      if (manipulatedResults === false) {
        this.status = `Abort. Upload cancelled by manipulator`;
        return;
      }

      if (this.pagelets.length >= manipulatedResults.length) {
        this.status = `Abort. Content has ${this.chapter.imageCount} images, save requested for ${manipulatedResults.length}; not saving.`;
        return;
      }

      this.status = `All files uploaded. Saving ${manipulatedResults.length} (${results.length} new) images to content`;

      await this.saveContentAndRefresh(manipulatedResults);

    },
    async saveContentAndRefresh(images) {
      this.checkpoint();
      const pratilipiId = this.episode.pratilipiId;
      const chapterId = this.chapter.id;

      const content = images
        .map(name => {
          return `<img src="api/pratilipi/content/comic?pratilipiId=${pratilipiId}&name=${name}"/>`;
        })
        .join("");

      const chapterSaveResult = await apiContentV1
        .saveChapterComics({
          // chapterNo: 1,
          chapterId,
          pratilipiId: this.episode.pratilipiId,
          content: `'${content}'`
        })
        .catch(e => {
          this.status = "Content saved failed. Reason: " + e.message;
          throw e;
        });

      this.status = "Content saved. Fetching fresh list of images";
      await this.fetchContent(pratilipiId);
      this.status = "";
    },

    async episodeActionUploadCoverImage(ev) {
      const file = ev.target.files[0];
      const img = new Image()

      let checkImageDimensions = new Promise((resolve, reject) => {
          img.src = window.URL.createObjectURL(file)
              img.onload = () => {
                  if(img.width > 500 || img.height > 500 || file.size > 1572864){
                      this.$alert("Image size should not exceed 1.5MB and maximum resolution should be 500 pixels(W) X 500 pixels(H)", "Upload Error!", "error");
                      this.triggerAnanlyticsEvent("SEEN_ALERT_EPISODE_COVER_IMAGE", "CONTROL", {
                        SERIES_ID: this.comic.seriesId,
                        SERIES_NAME: this.comic.titleEn,
                        IMG_HEIGHT: img.height,
                        IMG_WIDTH: img.width
                      });
                      reject();
                  }
                  else{
                    resolve();
                  }
              }

      });

      checkImageDimensions.then(async ()=>{
          const data = await apiPratilipiV1.saveCoverImage(
          this.episode.pratilipiId,
          file
          );
          this.$set(this.episode, "coverImageUrl", data.coverImageUrl);
      })
    },

    async onHeaderButtonClick(id) {
      if (id == "GOTO-SERIES") {
        this.goBackToComic();
      } else if (id == "SAVE-META") {
        await this.saveMeta();
      } else if (id == "UNPUBLISH") {
        await this.saveMeta({ state: "DRAFTED" });
      } else if (id == "PUBLISH") {
        await this.saveMeta({ state: "PUBLISHED" });
      } else if(id == "DELETE-EPISODE") {
        await this.deleteEpisode();
      }
    },

    checkpoint() {
      const key = `content_checkpoints_${this.episode.pratilipiId}`;

      let current = localStorage.getItem(key);
      current = JSON.parse(current);
      if (!Array.isArray(current)) {
        current = [];
      }

      const now = {
        timestamp: new Date().getTime(),
        images: _.map(this.pagelets, "data.name")
      };

      current = _.take([now, ...current], 10);

      localStorage.setItem(key, JSON.stringify(current));
    },

    onInputClick(field, choice) {
      this.triggerAnanlyticsEvent("CLICK_FIELD_" + field, "", {
        SERIES_ID: this.comic.seriesId,
        SERIES_NAME: this.comic.titleEn,
        PRATILIPI_ID: this.episode.pratilipiId,
        CHOICE: choice
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.writer-container {
  background-color: #f9f9f9;
}

.update-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  background: rgba(255, 255, 255, 0.4);
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;
  i {
    vertical-align: middle;
    font-size: 18px;
  }
}
</style>
